/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useStore } from '../Contexts/StoreContext';

export default function Menu() {
  const { setAccessToken, accessLevel } = useStore();

  const logOut = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_level');
    setAccessToken(null);
    window.location.reload();
  };

  return (
    <>
      <aside
        className="main-sidebar sidebar-dark-primary elevation-4"
        style={{ background: '#775DA8', position: 'fixed' }}
      >
        <div className="sidebar" style={{ height: '99vh' }}>
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column flex-nowrap"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink to="/" className={`nav-link `}>
                  <i className="nav-icon fas fa-th" />
                  <p>Dashboard</p>
                </NavLink>
              </li>
              <li
                className="nav-header rounded-2 text-bold my-2 "
                style={{ background: '#6c4da9' }}
              >
                COURSE
              </li>

              <li className="nav-item ">
                <NavLink to="/course-categories" className={`nav-link `}>
                  <i className="nav-icon fa fa-files-o" />
                  <p>Course Categories</p>
                </NavLink>
              </li>

              <li className="nav-item ">
                <NavLink to="/courses" className={`nav-link `}>
                  <i className="nav-icon fa fa-book" />
                  <p>Courses</p>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink to="/scholarship" className={`nav-link `}>
                  <i className="nav-icon fa fa-book" />
                  <p>Scholarship</p>
                </NavLink>
              </li>
              

              {accessLevel === 'admin' && (
                <>
                  {/* Show to Admin only */}
                  <li
                className="nav-header rounded-2 text-bold my-2 "
                style={{ background: '#6c4da9' }}
              >
                CUSTOMERS
              </li>
              <li className="nav-item ">
                <NavLink to="/customers" className={`nav-link `}>
                  <i className="nav-icon fas fa-users" />
                  <p>Customers</p>
                </NavLink>
              </li>
                  <li
                    className="nav-header rounded-2 text-bold my-2 "
                    style={{ background: '#6c4da9' }}
                  >
                    APP
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/welcome-screen" className={`nav-link `}>
                      <i className="nav-icon fa fa-mobile" />
                      <p>Welcome Screen</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/banner" className={`nav-link `}>
                      <i className="nav-icon fa fa-television" />
                      <p>Banner</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/add-image" className={`nav-link `}>
                      <i className="nav-icon fa fa-television" />
                      <p>Add Image</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/Testimonial" className={`nav-link `}>
                      <i className="nav-icon fa fa-television" />
                      <p>Testimonial</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/promocode" className={`nav-link `}>
                      <i className="nav-icon fa fa-television" />
                      <p>Promocode</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/event" className={`nav-link `}>
                      <i className="nav-icon fa fa-map-o" />
                      <p>Event</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/notification" className={`nav-link `}>
                      <i className="nav-icon fa fa-bell" />
                      <p>Notification</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/feedback" className={`nav-link `}>
                      <i className="nav-icon fa fa-bug" />
                      <p>Rating</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/faq" className={`nav-link `}>
                      <i className="nav-icon fa fa-question-circle" />
                      <p>FAQ</p>
                    </NavLink>
                  </li>
                  <li
                    className="nav-header rounded-2 text-bold my-2 "
                    style={{ background: '#6c4da9' }}
                  >
                    DETAILS
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/contact-us" className={`nav-link `}>
                      <i className="nav-icon fa fa-phone" />
                      <p>Contact Us</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/ContactQuery" className={`nav-link `}>
                      <i className="nav-icon fa fa-phone" />
                      <p>Contact Queries</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/Ticketquerylist" className={`nav-link `}>
                      <i className="nav-icon fa fa-phone" />
                      <p>Tickets List</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/about-us" className={`nav-link `}>
                      <i className="nav-icon fa fa-info-circle" />
                      <p>About Us</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/help" className={`nav-link `}>
                      <i className="nav-icon fa fa-life-ring" />
                      <p>Help</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/Payment" className={`nav-link `}>
                      <i className="nav-icon fa fa-life-ring" />
                      <p>Payment</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/privacy-policy" className={`nav-link `}>
                      <i className="nav-icon fa fa-lock" />
                      <p>Privacy Policy</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/terms-and-condition" className={`nav-link `}>
                      <i className="nav-icon fa fa-gavel" />
                      <p>Terms and Condition</p>
                    </NavLink>
                  </li>
                </>
              )}

              <li
                className="nav-header rounded-2 text-bold my-2 "
                style={{ background: '#6c4da9' }}
              >
                SETTINGS
              </li>
              <li className="nav-item">
                <NavLink to="/profile" className="nav-link">
                  <i className="nav-icon fas fa-user" />
                  <p>Profile</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={logOut}>
                  <i className="nav-icon fas fa-sign-out-alt" />
                  <p>Log Out</p>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}
