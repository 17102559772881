import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../../Utils/axios';
import { useStore } from '../../Contexts/StoreContext';
import { JsDateToString } from '../../Utils/dateEditor';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const Payment = () => {
  const { setIsLoading } = useStore();
  const [purchaseData, setPurchasedata] = useState([]);


  const [filteredData, setFilteredData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  const [searchTermFilter, setSearchTermFilter] = useState('');
  const [sortingOn, setSortingOn] = useState('screen_title');
  const [sortingMethod, setSortingMethod] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(20);
  const rowsPerPage = 20;

  // getting help data from database
  const fetchHelpData = async () => {
    try {
      setIsLoading(true);
      const response = await axios().get(`/api/v1/courses/fetch_orders`);
     
      console.log(response.data.purchase);
      setPurchasedata(response.data.purchase)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
     
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHelpData();
  
  }, [setIsLoading]);

  useEffect(() => {
    // filtering according to search term filter
      const filteredData = purchaseData.filter(item =>
          Object.values(item).some(value => {
            if (value !== null && value !== undefined) {
              return value.toString().toLowerCase().includes(searchTermFilter.toLowerCase());
            }
            return false;
          })
        );
  

    setFilteredData(filteredData);
  }, [purchaseData, searchTermFilter]);

  // sorting searchTermFilteredData according to sortingOn and sortingMethod
  useEffect(() => {
    const tempFilteredData = filteredData;

    const asc = (a, b) => {
      if (
        String(a[sortingOn]).toLowerCase() > String(b[sortingOn]).toLowerCase()
      )
        return 1;
      else if (
        String(a[sortingOn]).toLowerCase() < String(b[sortingOn]).toLowerCase()
      )
        return -1;
      else return 0;
    };
    const des = (a, b) => {
      if (
        String(a[sortingOn]).toLowerCase() < String(b[sortingOn]).toLowerCase()
      )
        return 1;
      else if (
        String(a[sortingOn]).toLowerCase() > String(b[sortingOn]).toLowerCase()
      )
        return -1;
      else return 0;
    };

    tempFilteredData.sort(sortingMethod ? asc : des);
    setSortedData(tempFilteredData);
  }, [filteredData, sortingMethod, sortingOn]);

  // paginating sortedData accordint to currentPage and rowsPerPage
  useEffect(() => {
    const indexOfLastUser = currentPage * rowsPerPage;
    const indexOfFirstUser = indexOfLastUser - rowsPerPage;
    setPaginatedData(sortedData.slice(indexOfFirstUser, indexOfLastUser));
  }, [currentPage, sortedData, rowsPerPage, sortingMethod]);

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    const totalPage = Math.ceil(sortedData.length / rowsPerPage);
    if (currentPage < totalPage) setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  <i className="nav-icon fa fa-life-ring me-2" />
                  Payment
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item ">Payment</li>
                </ol>
              </div>
            </div>

            <div className="card mt-5">
              {/* <div className="card-header d-flex">
                <h4 className="flex-grow-1">Payment</h4>
               
          
              </div> */}
              <div className="card-header d-flex">
                <input
                  type="text"
                  className="form-control flex-grow-1"
                  placeholder="Search for user payment, Name, Email, OrderId"
                  autoFocus={true}
                  value={searchTermFilter}
                  onChange={(e) => {
                    setSearchTermFilter(e.target.value);
                  }}
                />
            
              </div>
              <div style={{ overflowX: 'auto' }}>
                <table className='payment_detalis'>
                  <tr>
                    <th>#</th>
                    <th>Order id</th>
                    <th>User Name</th>
                    <th>User Mail</th>
                    <th>User Mobile No.</th>
                    {/* <th>Original Course Price (in ₹)</th> */}
                    <th>Discounted Amount (in ₹)</th>
                    {/* <th>Paid Amount (Excl GST) in ₹</th> */}
                    <th>GST Amount (in ₹)</th>
                    <th>Total Paid Amount (Incl GST) in ₹</th>
                    <th>Course Name</th>
                    <th>Course Duration</th>
                    <th>Purchase Date</th>
                    {/* <th>Last Date</th> */}
                    <th>Status</th>
                    
                  </tr>
                  {
                    paginatedData.map((purchase_data,index)=>{
                      return (
                        <tr key={index}>
                          <th scope="row">
                            {currentPage * rowsPerPage -
                              rowsPerPage +
                              index +
                              1}
                          </th>

                          <td>#{purchase_data?.transaction_id}</td>
                          <td>{purchase_data?.user_name}</td>
                          <td>{purchase_data?.user_email}</td>
                          <td>{purchase_data?.user_mobile_no}</td>
                          {/* <td>500</td> */}
                          <td>{purchase_data?.discount}</td>
                          {/* <td>180 ₹</td> */}
                          <td>{purchase_data?.gst}</td>
                          <td>{purchase_data.totalAmount}</td>
                          <td>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: purchase_data?.courseName?.replaceAll(
                                  "&lt;",
                                  "<"
                                ),
                              }}
                            ></p>
                            <Link
                              to={`/Paymentdetail/${purchase_data?.uniqueId}/${purchase_data?.planId}`}
                              className={`---View_more-- `}
                            >
                              <p>View Details</p>
                            </Link>
                          </td>
                          <td>{purchase_data?.planduration} months</td>
                          <td>
                            {purchase_data?.purchase_date.substring(
                              0,
                              purchase_data?.purchase_date.indexOf("T")
                            )}
                          </td>
                          {/* <td>4/3/2024</td> */}
                          {purchase_data?.status.toLowerCase() === "success" ? (
                            <td className="success">Success</td>
                          ) : (
                            <td className="failed">Failed</td>
                          )}
                        </tr>
                      );

                    })
                  }
                 
                
                </table>
              </div>
              <div className="card-footer clearfix d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-primary me-2"
                  onClick={prevPage}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </button>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  style={{ width: '100px', textAlign: 'center' }}
                  value={`${currentPage}/${
                    Math.ceil(purchaseData.length / rowsPerPage) || 1
                  }`}
                  readOnly={true}
                />
                <button
                  type="button"
                  className="btn btn-outline-primary ms-2"
                  onClick={nextPage}
                >
                  <i className="fa fa-arrow-right" aria-hidden="true" />
                </button>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
