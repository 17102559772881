
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../../Utils/axios';
import { useStore } from '../../Contexts/StoreContext';
import { JsDateToString, FormDateToJs } from '../../Utils/dateEditor';
import { DateTime } from 'luxon';
import { BiSolidOffer } from "react-icons/bi";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});


const Promocode = () => {
  const { setIsLoading } = useStore();
  const [promocodeData, setpromocodeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  const [searchTermFilter, setSearchTermFilter] = useState('');
  const [notificationTypeFilter, setNotificationTypeFilter] = useState('');
  const [sortingOn, setSortingOn] = useState('notification_title');
  const [sortingMethod, setSortingMethod] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [promocodesdata, setpromocodesdata] = useState([])
  // const [rowsPerPage, setRowsPerPage] = useState(20);
  const rowsPerPage = 20;


  // getting notification data from database
  const fetchpromoData = async () => {
    try {
      setIsLoading(true);
      let response = await axios().get(`/api/v1/promocodes`);
      let promocodes = response.data
      setpromocodeData(promocodes);
      console.log(promocodes);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchpromoData();
   
  }, [setIsLoading]);

  // FILTERING DATA IN ONE GO
  useEffect(() => {
    // filtering according to search term filter
    const temppromocodeData = promocodeData;

    const tempSearchTermFilterData = temppromocodeData.filter(
      (category) => {
        if (searchTermFilter === '') {
          return true;
        } else {
          if (
            category['code']
              .toLowerCase()
              .includes(searchTermFilter.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    );

    setFilteredData(tempSearchTermFilterData);
  }, [promocodeData, notificationTypeFilter, searchTermFilter]);

  // sorting searchTermFilteredData according to sortingOn and sortingMethod
  useEffect(() => {
    const tempFilteredData = filteredData;

    const asc = (a, b) => {
      if (
        String(a[sortingOn]).toLowerCase() > String(b[sortingOn]).toLowerCase()
      )
        return 1;
      else if (
        String(a[sortingOn]).toLowerCase() < String(b[sortingOn]).toLowerCase()
      )
        return -1;
      else return 0;
    };
    const des = (a, b) => {
      if (
        String(a[sortingOn]).toLowerCase() < String(b[sortingOn]).toLowerCase()
      )
        return 1;
      else if (
        String(a[sortingOn]).toLowerCase() > String(b[sortingOn]).toLowerCase()
      )
        return -1;
      else return 0;
    };

    tempFilteredData.sort(sortingMethod ? asc : des);
    setSortedData(tempFilteredData);
  }, [filteredData, sortingMethod, sortingOn]);

  // paginating sortedData accordint to currentPage and rowsPerPage
  useEffect(() => {
    const indexOfLastUser = currentPage * rowsPerPage;
    const indexOfFirstUser = indexOfLastUser - rowsPerPage;
    setPaginatedData(sortedData.slice(indexOfFirstUser, indexOfLastUser));
  }, [currentPage, sortedData, rowsPerPage, sortingMethod]);

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    const totalPage = Math.ceil(sortedData.length / rowsPerPage);
    if (currentPage < totalPage) setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                 <BiSolidOffer size={40}/>
                  Promocode
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>

                  {/* <li className="breadcrumb-item">App</li> */}
                  <li className="breadcrumb-item">Promocode</li>
                </ol>
              </div>
            </div>

            <div className="card mt-5">
              <div className="card-header d-flex">
                <input
                  type="text"
                  className="form-control flex-grow-1"
                  placeholder="Search for promocode"
                  autoFocus={true}
                  value={searchTermFilter}
                  onChange={(e) => {
                    setSearchTermFilter(e.target.value);
                  }}
                />
                <ManageNotificationModal
                  fetchpromocodeData={promocodeData}
                />
              </div>
              <div className="card-body" style={{ overflow: 'auto' }}>
                <table
                  className="table table-hover"
                  style={{ minWidth: '840px' }}
                >
                  <thead className="table-light">
                    <tr>
                      <th scope="col">#</th>
                      <th
                        scope="col"
                    
                      >
                        code
                        <i className="ms-2 fa fa-sort" aria-hidden="true" />
                      </th>
                      <th scope="col">
                      Discount
                      </th>
                      <th scope="col">startDate
                      </th>
                      <th scope="col">endDate

                      </th>
                      <th scope="col">
                       TimesUsed
                       </th>
                      <th scope="col">
                       Totaluses
                       </th>
                      <th scope="col">
                       Manage
                       </th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    <TableContent
                      fetchpromocodetData={fetchpromoData}
                      paginatedData={paginatedData}
                      currentPage={currentPage}
                      rowsPerPage={rowsPerPage}
                    />
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-primary me-2"
                  onClick={prevPage}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </button>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  style={{ width: '100px', textAlign: 'center' }}
                  value={`${currentPage}/${
                    Math.ceil(paginatedData.length / rowsPerPage) || 1
                  }`}
                  readOnly={true}
                />
                <button
                  type="button"
                  className="btn btn-outline-primary ms-2"
                  onClick={nextPage}
                >
                  <i className="fa fa-arrow-right" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Promocode;

const TableContent = ({
  fetchpromocodetData,
  paginatedData,
  currentPage,
  rowsPerPage,
}) => {

  let datetime = new Date()
  return (
    <>
      {paginatedData.length === 0 ? (
        <tr>
          <td colSpan="8" className="text-center">
            No data
          </td>
        </tr>
      ) : (
        paginatedData.map((data, index) => {
          return (
            <tr key={data._id}>
              <th scope="row">
                {currentPage * rowsPerPage - rowsPerPage + index + 1}
              </th>
              <td>
          
                {data.code}
              </td>
              <td>{data.discountPercentage} %</td>
             <td>{new Date(data.startDate).toISOString().split('T')[0]}</td>
             <td>{new Date(data.endDate).toISOString().split('T')[0]}</td>
             <td>{data.timesUsed}</td>
              <td>{data.usageLimit}</td>
              <td>
                <ManageNotificationModal
                  data={data}
                  fetchpromocodeData={fetchpromocodetData}
                />
              </td>
            </tr>
          );
        })
      )}
    </>
  );
};

const ManageNotificationModal = ({ data, fetchpromocodeData }) => {
  const CloseButton = useRef();
  const { setIsLoading } = useStore();

console.log("data", data)
  const initialLocalData = {
    courseSeriesPlanId: '',
    code: '',
    discountPercentage: '',
    startDate: '',
    endDate: '',
    usageLimit:'',

  };

  const [localData, setLocalData] = useState(initialLocalData);
  console.log("localdata",localData)
  const [coursesData, setCoursesData] = useState([]) 
  const [courseId, setcourseId] = useState(null)
  const [seriesData, setSeriesData] = useState([])
  const [seriesId, setSeriesID] = useState(null)
  const [planData, setplanData] = useState([])



  useEffect(() => {
    if (!data) return;

    setLocalData(data);
  }, [data]);

  const handleAddPromocode = async () => {
    let response;
    try {
      setIsLoading(true);
      response = await axios().post(`/api/v1/promocodes`, localData);
      Toast.fire({
        icon: 'success',
        title: 'Promocode added',
      });
      setLocalData(initialLocalData); 
      CloseButton.current.click();
      setIsLoading(false);
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });
      setIsLoading(false);
    }
  };
                                             
  const handleUpdatepromocode = async () => {
    try {
      setIsLoading(true);
      await axios().put(`/api/v1/promocodes/${data._id}`, localData);
      Toast.fire({
        icon: 'success',
        title: 'Promocode updated',
      });
      fetchpromocodeData();
      CloseButton.current.click();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });
      setIsLoading(false);
    }
  };

  const handleDeletepromocode = async () => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      html: '<h6>This promocode will get permanently deleted</h6>',
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: '#D14343',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          await axios().delete(`/api/v1/promocodes/${data._id}`);
          Toast.fire({
            icon: 'success',
            title: 'Promocede deleted',
          });
          setTimeout(function () {
            fetchpromocodeData();
          }, 500);
          CloseButton.current.click();
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          Toast.fire({
            icon: 'error',
            title: error.response.data
              ? error.response.data.msg
              : error.message,
          });
          setIsLoading(false);
        }
      }
    });
  };


  
// get all course data from database
const fetchCourseData = async()=>{
  try {
    setIsLoading(true)
    const courseResponse = await axios().get(`/api/v1/courses/course/all`);
    setCoursesData(courseResponse.data.courses);
    setIsLoading(false)
    
   } catch (error) {
    console.log(error);
    Toast.fire({
      icon: 'error',
      title: error.response.data ? error.response.data.msg : error.message,
    });
    setIsLoading(false);
  }
}

useEffect(()=>{
  fetchCourseData()
},[])

// get series data form database
  const fetchSeries = async(courseId)=>{
    try {
      setIsLoading(true)
      const response = await axios().get(`/api/v1/courses/series/${courseId}`);
    setSeriesData(response.data.series)
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });
      setIsLoading(false);
    }
  }

const handlecourseID = (e)=>{
 const selectedCourseId = e.target.value;
 setcourseId(selectedCourseId)
 fetchSeries(selectedCourseId)
 setIsLoading(false)
}

// get courseplan from database
const fetchPlan = async(seriesId)=>{
try {
  setIsLoading(true)
  const response = await axios().get(`/api/v1/courses/plan/${seriesId}`)
  setplanData(response.data.plans)
} catch (error) {
  console.log(error);
  Toast.fire({
    icon: 'error',
    title: error.response.data ? error.response.data.msg : error.message,
  });
  setIsLoading(false);
}
}

const handleSeriesId = (e)=>{
  const selectSeriesId = e.target.value;
   setSeriesID(selectSeriesId)
   fetchPlan(selectSeriesId)
   setIsLoading(false)
}


const[changeInputType,setInputType]=useState("text")
  return (
    <>
      <button
        type="button"
        className="btn btn-dark ms-2 d-flex align-items-center"
        data-toggle="modal"
        data-target={data ? `#${data._id}` : '#add-notification-modal'}
      >
        {data ? (
          <i className="fa fa-cog" aria-hidden="true" />
        ) : (
          <>
            <i className="fa fa-plus me-1" aria-hidden="true" /> Promocode
          </>
        )}
      </button>
      <div
        className="modal fade"
        id={data ? data._id : 'add-notification-modal'}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <form onSubmit={handleAddPromocode}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {data ? <>Manage Promocode</> : <>Add Promocode</>}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseButton}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <label htmlFor="selectcourse" className="form-label mt-2">
                Select Course
              </label>
             {data? (
              <input type="text" 
              className="form-control"
              value={data.courseSeriesPlanId.of_course_series.of_course.course_title}
              disabled
              />
             ):(
              <select id="selectcourse"
              className="form-select w-100"
              required
              value={courseId}
              onChange={handlecourseID}
              >
              { coursesData.map((course)=>(
                <option value={course._id} key={course._id}>{course.course_title}</option>
               ))}
               </select>
             )}

              <label htmlFor="selectseries" className="form-label mt-2">
                Select Series
              </label>
             {data?(
                   <input type="text" 
                   className="form-control"
                   value={data.courseSeriesPlanId.of_course_series.series_name}
                   disabled
                   />
             ):(
              <select id="selectseries"
              className="form-select w-100"
              required
              value ={seriesId}
              onChange={handleSeriesId}
              >
              { seriesData.map((series)=>(
                <option value={series._id} key={series._id} >{series.series_name}</option>
               ))}
               </select>
             )}
               <label htmlFor="selectplan" className="form-label mt-2">
                Select Plan
              </label>
              {data ? (
                <input type="text" 
                className="form-control"
                value={data.courseSeriesPlanId.plan_description}
                disabled
                />
      
              ):(
                <>
              <select
              id="selectplan"
              required
              value={localData.courseSeriesPlanId}
              className="form-select w-100"
              onChange={(e)=>
                setLocalData({
                  ...localData,
                  courseSeriesPlanId: e.target.value,
                })
              }
              >
              { planData.map((plan)=>(
                <>
                 <option value={plan._id} key={plan._id} className="form-control">{plan.plan_description}</option>
                 </>
              ))}
               </select> 
                </>
              )}
              <label htmlFor="notification_title" className="form-label mt-2">
                Code
              </label>
              <input
              required
                type="text"
                className="form-control"
                id="notification_title"
                value={localData.code?.replaceAll('&lt;', '<')}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    code: e.target.value,
                  })
                }
              />

              <label htmlFor="notification_type" className="form-label mt-2">
                Discount
              </label>
           <input
              required
              type='text'
              className="form-control"
                id="notification_type"
                value={localData.discountPercentage}
                onChange={(e) => {
                  setLocalData({
                    ...localData,
                    discountPercentage: e.target.value,
                  });
                }}
              />
              <label htmlFor="startDate" className="form-label mt-2">
              Start Date
              </label>
              <input
              required
                type={changeInputType}
                className="form-control"
                id="startDate"
                value={localData.startDate}
                onFocus={
                  ()=>setInputType('datetime-local')
                }
                onChange={(e) => {
                  setLocalData({
                    ...localData,
                    startDate: e.target.value,
                  });
                }}
                // onChange={datashowe}
              />
             
            

              <label
                htmlFor="endDate"
                className="form-label mt-2"
              >
            End Date
              </label>
              <input
              required
                type={changeInputType}
                min={localData.startDate}
                className="form-control"
                id="endDate"
              
                value={localData.endDate}
                onFocus={()=>setInputType("datetime-local")}
                onChange={(e) => {
                  setLocalData({
                    ...localData,
                    endDate: e.target.value,
                  });
                }}
           
              />
               <label htmlFor="notification_title" className="form-label mt-2">
               UsageLimit
              </label>
              <input
              required
                type="text"
                className="form-control"
                id="notification_title"
                value={localData.usageLimit}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    usageLimit: e.target.value,
                  })
                }
              />
              
            </div>
            <div className="modal-footer">
              {data ? (
                <>
                  {/* Manage */}
                  <button
                    type="button"
                    className="btn btn-danger me-auto"
                    onClick={handleDeletepromocode}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleUpdatepromocode}
                  >
                    Save changes
                  </button>
                </>
              ) : (
                <>
                  {/* Add New */}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    // onClick={handleAddNotification}
                  >
                    Add
                  </button>
                </>
              )}
            </div>
          </div>
          </form>
        </div>
      </div>
    </>
  );
};
