import { React, useState } from 'react';
import Swal from 'sweetalert2';
import { useStore } from '../../../Contexts/StoreContext';
import axios from '../../../Utils/axios';
import { useNavigate, Link } from 'react-router-dom';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const initialState = {
  name: '',
  email: '',
  country_code: '',
  phone_no: '',
  password: '',
  confirmPassword: '',
};

const SignUp = () => {
  const { setIsLoading } = useStore();
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userDetail.password !== userDetail.confirmPassword) {
      Toast.fire({
        icon: 'error',
        title: 'Password and Confirm Password do not match.',
      });
      return;
    }

    setIsLoading(true);
    try {
      const res = await axios().post('/api/v1/auth/register', userDetail);

      console.log(res);

      Toast.fire({
        icon: 'success',
        title: res.data.msg,
      });

      setIsLoading(false);

      navigate('/sign-in');
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: 'error',
        title: err.response.data ? err.response.data.msg : err.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <form className="form-horizontal">
        <h2 style={{ color: '#775DA8' }}>Registration | User</h2>
        <h6>Please enter details</h6>

        <div className="form-group row">
          <label htmlFor="nameField" className="form-label">
            Name
          </label>
          <div className="">
            <input
              type="text"
              name="name"
              className="form-control"
              id="nameField"
              placeholder="Enter your name"
              value={userDetail.name}
              onChange={(e) =>
                setUserDetail({ ...userDetail, name: e.target.value })
              }
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="emailField" className="form-label">
            Email
          </label>
          <div className="">
            <input
              type="email"
              name="email"
              className="form-control"
              id="emailField"
              placeholder="abc@email.com"
              value={userDetail.email}
              onChange={(e) =>
                setUserDetail({ ...userDetail, email: e.target.value })
              }
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="phoneNoField" className="form-label">
            Phone No
          </label>
          <div className="d-flex">
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                +
              </span>
              <input
                type="text"
                className="form-control"
                id="countryCodeField"
                style={{ maxWidth: '50px' }}
                placeholder="XX"
                value={userDetail.country_code}
                onChange={(e) =>
                  setUserDetail({
                    ...userDetail,
                    country_code: Number(e.target.value),
                  })
                }
              />
              <input
                type="text"
                className="form-control"
                id="phoneNoField"
                placeholder="XXXXXXXXXX"
                value={userDetail.phone_no}
                onChange={(e) =>
                  setUserDetail({
                    ...userDetail,
                    phone_no: Number(e.target.value),
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="passwordField" className="form-label">
            Password
          </label>
          <div className="">
            <div className="input-group mb-1">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                className="form-control  border-right-0"
                id="passwordField"
                placeholder="Password"
                value={userDetail.password}
                onChange={(e) =>
                  setUserDetail({ ...userDetail, password: e.target.value })
                }
              />
              <span
                className="input-group-text bg-white"
                id="basic-addon2"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <>
                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                  </>
                ) : (
                  <>
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  </>
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="confirmPasswordField" className="form-label">
            Confirm Password
          </label>
          <div className="">
            <div className="input-group mb-1">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                className="form-control  border-right-0"
                id="confirmPasswordField"
                placeholder="Password"
                value={userDetail.confirmPassword}
                onChange={(e) =>
                  setUserDetail({
                    ...userDetail,
                    confirmPassword: e.target.value,
                  })
                }
              />
              <span
                className="input-group-text bg-white"
                id="basic-addon2"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <>
                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                  </>
                ) : (
                  <>
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  </>
                )}
              </span>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-success w-100"
          onClick={(e) => handleSubmit(e)}
        >
          Register
        </button>
        <div className="my-2 text-center">
          <Link to="/sign-in">Already Register?</Link>
        </div>
      </form>
    </>
  );
};

export default SignUp;
