import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../../Utils/axios';
import { useStore } from '../../Contexts/StoreContext';
import { useNavigate, useLocation } from 'react-router-dom';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});



const Course = () => {
  const parems = useParams();
  const { uniqueId,planId } = parems;
  const { setIsLoading } = useStore();

  const [purchaseData, setPurchasedata] = useState([]);
  const [purchaseDetail, setPurchasedetail] = useState([]);

  console.log(uniqueId);
  console.log(purchaseDetail);

  // getting help data from database
  const fetchHelpData = async () => {
    try {
      setIsLoading(true);
      const response = await axios().get(`/api/v1/courses/fetch_orders`);

      console.log(response.data.purchase);
      const datasend = response.data.purchase;
      setPurchasedata(datasend);
      const datauniqueId = datasend.filter((x) => x.uniqueId === uniqueId && x.planId === planId);
      setPurchasedetail(datauniqueId);

      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHelpData();
  }, [uniqueId]);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  <i className="nav-icon fa fa-book me-2" />
                  Payment Details
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Payment">Payment</Link>
                  </li>
                  <li className="breadcrumb-item ">Payment Details</li>
                </ol>
              </div>
            </div>

            <div className="card mt-5">
              {purchaseDetail.map((itemdata, index) => {
                return (
                  <div className="static_data_payment">
                    <div className="catagary_by_data_payment">
                      <h3>User Details</h3>
                      <div style={{ padding: "0 20px" }}>
                        <div className="User_payment_details">
                          <p>User Name</p>
                          <span>{itemdata.user_name}</span>
                        </div>
                        <div className="User_payment_details">
                          <p>User E-mail</p>
                          <span>{itemdata.user_email}</span>
                        </div>
                        <div className="User_payment_details">
                          <p>User Mobile No.</p>
                          <span>{itemdata.user_mobile_no}</span>
                        </div>
                      </div>
                    </div>

                    <div className="catagary_by_data_payment">
                      <h3>Coures Details</h3>

                      <div style={{ padding: "0 20px" }}>
                        <div style={{ marginBottom: "20px" }}>
                          <div className="User_payment_details_three">
                            <p>Course Name</p>
                            <span
                             dangerouslySetInnerHTML={{
                              __html: itemdata.courseName?.replaceAll('&lt;', '<'),
                            }}></span>
                          </div>
                          <div>
                            <ul>
                              <li>
                                <div className="User_payment_details_two">
                                  <p>Course series Name</p>
                                  <span
                                   dangerouslySetInnerHTML={{
                                    __html: itemdata.seriesName?.replaceAll('&lt;', '<'),
                                  }}
                                  ></span>
                                </div>
                                {/* <ul>
                                  <li>
                                    <div className="coures_plan_details">
                                      <p>Course Plan Name</p>
                                      <span>{itemdata.planName}</span>
                                    </div>
                                  </li>
                                </ul> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="User_payment_details">
                          <p>Plan Duration</p>
                          <span>{itemdata.planduration} months</span>
                        </div>
                        <div className="User_payment_details">
                          <p>Purchase Date</p>
                          {/* <span>{itemdata.purchase_date}</span> */}
                          <span>{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'medium', timeZone: 'UTC' }).format(new Date(`${itemdata?.purchase_date}`))}</span>
                        </div>
                        {/* <div className="User_payment_details">
                          <p>Last Date</p>
                          <span>4/3/2024</span>
                        </div> */}
                      </div>
                    </div>

                    <div className="catagary_by_data_payment">
                      <h3>Payment Details</h3>
                      <div style={{ padding: "0 20px" }}>
                        <div className="User_payment_details">
                          <p>Original Price (in ₹)</p>
                         <span> <del className=''>{itemdata.planOriginalPrice} ₹</del></span>
                        </div>
                        <div className="User_payment_details">
                          <p>Discounted Amount (in ₹)</p>
                          <span>+{itemdata.discount} ₹</span>
                        </div>
                        
                        <div className="User_payment_details">
                          <p>GST Amount (in ₹)</p>
                          <span>+{itemdata.gst} ₹</span>
                        </div>
                        <div className="User_payment_details">
                        <p>Referred Point </p>
                        <span>-{itemdata.referredAmount} ₹</span>
                      </div>
                        <div className="User_payment_details">
                          <p>Total Paid Amount (Incl GST) in ₹</p>
                          <span>{itemdata.totalAmount} ₹</span>
                        </div>
                      </div>
                    </div>
                    <h5 className={`${itemdata.status.toLowerCase() === "success" ? "status_success" : "status_failed"}`}>{itemdata.status}</h5>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Course;
